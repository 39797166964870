import React, { useState } from "react";
import { Button, Form, Card, Row, Col } from "react-bootstrap";

interface UploadUrlProps {
  username: string;
  refreshDocuments: () => void;
}

const UploadUrl: React.FC<UploadUrlProps> = ({
  username,
  refreshDocuments,
}) => {
  const [url, setUrl] = useState<string>("");
  const [response, setResponse] = useState<string>("");
  const [typingIndicator, setTypingIndicator] = useState<string>("");

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  };

  const uploadUrl = async () => {
    if (url.trim() === "") {
      setResponse("No URL provided");
      return;
    }

    setResponse("");
    setTypingIndicator("Processing URL...");

    try {
      const res = await fetch(
        `https://themain.ro/ai/upload-url?url=${encodeURIComponent(
          url
        )}&user=${encodeURIComponent(username)}`,
        {
          method: "POST",
        }
      );

      if (res.ok) {
        setResponse("URL uploaded successfully");
        refreshDocuments();
      } else {
        setResponse("URL upload failed");
      }
    } catch (error) {
      if (error instanceof Error) {
        setResponse(`Error: ${error.message}`);
      } else {
        setResponse("An unexpected error occurred");
      }
      console.error("Upload error:", error);
    } finally {
      setTypingIndicator("");
    }
  };

  return (
    <Card className="p-3 mb-3">
      <Row>
        <Col>
          <Form.Label>Upload from URL:</Form.Label>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <Form.Group controlId="upload-url" className="w-100">
            <Form.Control type="text" value={url} onChange={handleUrlChange} />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Button onClick={uploadUrl} className="w-100">
            Upload URL
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mt-2 response">{response}</div>
          <div className="typing-indicator">{typingIndicator}</div>
        </Col>
      </Row>
    </Card>
  );
};

export default UploadUrl;
