import React, { useState, useEffect } from 'react';
import UsernameForm from './components/UsernameForm';
import MainContent from './components/MainContent';
import 'bootstrap/dist/css/bootstrap.min.css';

const apiUrl = process.env.REACT_APP_DOMAIN;

console.log('apiUrl:', apiUrl);

const App: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [isUsernameSet, setIsUsernameSet] = useState<boolean>(false);
  const [documents, setDocuments] = useState<string[]>([]);

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
      setIsUsernameSet(true);
    }
  }, []);

  useEffect(() => {
    if (isUsernameSet) {
      loadUserDocuments();
    }
  }, [isUsernameSet]);

  const loadUserDocuments = async () => {
    if (!username) {
      alert('Please enter a username.');
      return;
    }
  
    try {
      const response = await fetch(`${apiUrl}/vectorstore/documents?user=${encodeURIComponent(username)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers that your server expects
        },
        credentials: 'include', // This is crucial if your server expects credentials
      });
      
      if (!response.ok) {
        throw new Error(`Error fetching documents: ${response.statusText}`);
      }
  
      const docs = await response.json();
      setDocuments(docs);
    } catch (error) {
      console.error('Error loading documents:', error);
      window.location.href = "/login";
    }
  };

  const refreshDocuments = () => {
    loadUserDocuments();
  };

  if (!isUsernameSet) {
    return <UsernameForm setUsername={(name: string) => { setUsername(name); setIsUsernameSet(true); }} />;
  }

  return (
    <div>
      <MainContent username={username} setIsUsernameSet={setIsUsernameSet} documents={documents} refreshDocuments={refreshDocuments} />
    </div>
  );
};

export default App;
