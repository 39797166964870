import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import logo from "../assets/aibadge.svg";

import  "../assets/style.scss";
interface UsernameFormProps {
  setUsername: (name: string) => void;
}

const UsernameForm: React.FC<UsernameFormProps> = ({ setUsername }) => {
  const [inputUsername, setInputUsername] = useState('');

  const handleUsernameSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    localStorage.setItem('username', inputUsername);
    setUsername(inputUsername);
  };

  return (
    <Container fluid className="d-flex vh-100">
      <Row className="w-100 d-flex justify-content-center align-items-center">
        <Col md={3}>
          <Card>
            <Card.Body>
            <Card.Img variant="top" src={logo} />
              <Form onSubmit={handleUsernameSubmit}>
                <Form.Group controlId="formUsername">
                  <Form.Label>AI name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Please enter the AI name"
                    value={inputUsername}
                    onChange={(e) => setInputUsername(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100 mt-3">
                  Manage
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UsernameForm;
