import React, { useState } from 'react';
import { Button, Form, Card, Row, Col } from 'react-bootstrap';

interface UploadFileProps {
  username: string;
  refreshDocuments: () => void;
}

const UploadFile: React.FC<UploadFileProps> = ({ username, refreshDocuments }) => {
  const [file, setFile] = useState<File | null>(null);
  const [response, setResponse] = useState<string>('');
  const [typingIndicator, setTypingIndicator] = useState<string>('');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const uploadFile = async () => {
    if (!file) {
      setResponse('No file selected');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('user', username);

    setResponse('');
    setTypingIndicator('Processing file...');

    try {
      const res = await fetch('https://themain.ro/ai/upload', {
        method: 'POST',
        body: formData,
      });

      if (res.ok) {
        setResponse('File uploaded successfully');
        refreshDocuments();
      } else {
        setResponse('File upload failed');
      }
    } catch (error) {
      if (error instanceof Error) {
        setResponse(`Error: ${error.message}`);
      } else {
        setResponse('An unexpected error occurred');
      }
      console.error('Upload error:', error);
    } finally {
      setTypingIndicator('');
    }
  };

  return (
    <Card className='p-3 mb-3'>
      <Row>
        <Col>
        <Form.Label>Upload a File:</Form.Label>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
        <Form.Control type="file" onChange={handleFileChange} />
        </Col>
        <Col md={4}>
        <Button onClick={uploadFile} className='w-100'>Upload</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mt-2 response">{response}</div>
          <div className="typing-indicator">{typingIndicator}</div>
        </Col>
      </Row>

    </Card>
  );
};

export default UploadFile;
