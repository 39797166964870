import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  ButtonGroup,
} from "react-bootstrap";
import logo from "../assets/aibadge.svg";
import UploadFile from "./UploadFile";
import UploadUrl from "./UploadUrl";
import UserDocuments from "./UserDocuments";
import AskDocumentQuestion from "./AskDocumentQuestion";
import GenerateEmbedCode from "./GenerateEmbedCode";
import NavbarComponent from "./NavbarComponent";
import Modal from "react-bootstrap/Modal";
import "../assets/style.scss";

const apiUrl = process.env.REACT_APP_DOMAIN;

interface MainContentProps {
  username: string;
  setIsUsernameSet: (isSet: boolean) => void;
  documents: string[];
  refreshDocuments: () => void;
}

interface RequestData {
  local: number;
  remote: number;
}

const MainContent: React.FC<MainContentProps> = ({
  username,
  setIsUsernameSet,
  documents,
  refreshDocuments,
}) => {
  const changeUser = () => {
    localStorage.clear();
    setIsUsernameSet(false);
  };

  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowStats, setModalShowStats] = React.useState(false);
  const [persona, setPersona] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [shouldTrigger, setShouldTrigger] = React.useState(false);
  const [requestData, setRequestData] = React.useState<RequestData | undefined>(
    undefined
  );
  const handleShow = () => {
    setModalShow(!modalShow);
  };
  const handleShowStats = () => {
    setModalShowStats(!modalShowStats);
  };

  const loadPersona = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/user/ai-persona?user=${encodeURIComponent(username)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      const personaData = await response.text();
      setPersona(personaData);
    } catch (error) {
      console.warn("Error loading persona");
    }
  };

  const setRemote = async () => {
    setShouldTrigger(false);
    try {
      const response = await fetch(
        `${apiUrl}/user/remote-only?user=${encodeURIComponent(
          username
        )}&isRemoteOnly=${checked}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any other headers that your server expects
          },
          credentials: "include", // This is crucial if your server expects credentials
        }
      );
    } catch (error) {
      console.warn("Error setting remote flag");
    }
  };

  useEffect(() => {
    loadPersona();
  }, [modalShow]);

  const handlePersonaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPersona(event.target.value);
  };

  useEffect(() => {
    if (modalShowStats) {
      loadRemote();
      loadRequests();
    }
  }, [modalShowStats]);

  const loadRequests = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/user/request-counts?user=${encodeURIComponent(username)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      const requestData = await response.json();
      setRequestData(requestData);
    } catch (error) {
      console.warn("Error loading remote switch");
    }
  };

  const loadRemote = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/user/remote-only?user=${encodeURIComponent(username)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      const personaData = await response.text();
      const isPersonaDataTrue = personaData.trim().toLowerCase() === "true";
      setChecked(isPersonaDataTrue);
    } catch (error) {
      console.warn("Error loading remote switch");
    }
  };

  const saveNewPersona = async () => {
    console.log("newPersona", persona);
    try {
      const response = await fetch(
        `${apiUrl}/user/ai-persona?user=${encodeURIComponent(
          username
        )}&aiPersona=${persona}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any other headers that your server expects
          },
          credentials: "include", // This is crucial if your server expects credentials
        }
      );
    } catch (error) {
      console.warn("Error setting persona");
    }
  };

  useEffect(() => {
    if (shouldTrigger) {
      setRemote();
    }
  }, [checked, shouldTrigger]);

  const handleRemoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShouldTrigger(true);
    setChecked(!checked);
  };

  return (
    <>
      <NavbarComponent />
      <Container className="mt-5">
        <Row className="w-100 mt-20 justify-content-center ">
          <Col md={4}>
            <Card>
              <Card.Body>
                <Row>
                  <Col className="text-center">
                    <h4>AI name: {username}</h4>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card.Img variant="top" src={logo} style={{marginTop:16, padding:0}} />
                  </Col>
                  <Col><ButtonGroup vertical className="w-100 mt-5">
                  <Button
                    variant="primary"
                    className="w-100"
                    onClick={handleShow}
                  >
                    AI Persona
                  </Button>
                  <Button
                    variant="primary"
                    className="w-100"
                    onClick={handleShowStats}
                  >
                    AI Stats
                  </Button>
                </ButtonGroup></Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      variant="secondary"
                      onClick={changeUser}
                      type="submit"
                      className="w-100 mt-3"
                    >
                      Switch AI
                    </Button>
                  </Col>
                </Row>

                
              </Card.Body>
            </Card>
            <UserDocuments
              username={username}
              documents={documents}
              refreshDocuments={refreshDocuments}
            />
            <GenerateEmbedCode username={username} />
          </Col>
          <Col md={8}>
            <AskDocumentQuestion username={username} />
            <UploadFile
              username={username}
              refreshDocuments={refreshDocuments}
            />
            <UploadUrl
              username={username}
              refreshDocuments={refreshDocuments}
            />
          </Col>
        </Row>
      </Container>
      <Modal
        show={modalShow}
        onHide={handleShow}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Change AI Persona
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="textarea"
            rows={5}
            value={persona}
            onChange={handlePersonaChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={saveNewPersona}>Save changes</Button>
          <Button onClick={handleShow} variant="secondary">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalShowStats}
        onHide={handleShowStats}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">AI Stats</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>Local Requests:</strong> {requestData?.local ?? "N/A"} <br />
          <strong>Remote Requests:</strong> {requestData?.remote ?? "N/A"}{" "}
          <br />
          <Form.Check // prettier-ignore
            type="switch"
            id="custom-switch"
            className="mt-2"
            checked={checked}
            onChange={handleRemoteChange}
            label="Only remote requests"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MainContent;
