import React, { useState } from "react";
import { Button, Form, Card, Row, Col } from "react-bootstrap";
import CryptoJS from "crypto-js";

interface GenerateEmbedCodeProps {
  username: string;
}

const GenerateEmbedCode: React.FC<GenerateEmbedCodeProps> = ({ username }) => {
  // Define your parameters
  const param1 = username;

  const secret = "thisIsTheSecret&**@$nASXVZBASD*@$&&&@";
  const param2 = CryptoJS.HmacSHA256(username, secret).toString();

  const [buttonText, setButtonText] = useState("AI Chat");
  const [buttonColor, setButtonColor] = useState("#8d03ff");
  const [textColor, setTextColor] = useState("#FFFFFF");
  const [robotTitle, setRobotTitle] = useState("AI Assistant");
  const [personaName, setPersonaName] = useState("Assistant");
  const [useDdcIcon, setUseDdcIcon] = useState(false);

  // Use template literals to create the embedCode variable
  const embedCode = `<script src="https://jsdeliver.ddc.expert/v1/ddcrobot.js" async crossorigin="anonymous"
    id="ddcrobot-script"
    data-ddcRobotName="${param1}"
    data-ddcRobotHash="${param2}"
    data-ddcRobotText="${buttonText}"
    data-ddcRobotColor="${buttonColor}"
    data-ddcRobotTextColor="${textColor}"
    data-ddcRobotTitle="${robotTitle}"
    data-ddcRobotPersona="${personaName}"
    data-ddcRobotUseDdcIcon="${useDdcIcon}"
    ></script>`;

  const copyCode = async () => {
    try {
      await navigator.clipboard.writeText(embedCode);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const handleButtonTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setButtonText(event.target.value);
  };

  const handleTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRobotTitle(event.target.value);
  };

  const handlePersonaChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPersonaName(event.target.value);
  };

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setButtonColor(event.target.value);
  };

  const handleTextColor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextColor(event.target.value);
  };

  const handleUseDdcIconChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUseDdcIcon(event.target.checked);
  };

  return (
    <Card className="p-3 mb-3">
      <Row>
        <Col>
          <Form.Label>Chat button text</Form.Label>
          <Form.Control
            defaultValue={buttonText}
            onChange={handleButtonTextChange}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Form.Label>Chat title</Form.Label>
          <Form.Control defaultValue={robotTitle} onChange={handleTitleChange} />
        </Col>
        <Col>
          <Form.Label>Persona name</Form.Label>
          <Form.Control
            defaultValue={personaName}
            onChange={handlePersonaChange}
          />
        </Col>
      </Row>
      
      <Row className="mt-3">
        <Col>
          <Form.Label htmlFor="textCol">Chat button text</Form.Label>
          <Form.Control
            type="color"
            id="textCol"
            defaultValue={textColor}
            title="Choose text color"
            onChange={handleTextColor}
          />
        </Col>
        <Col>
          <Form.Label htmlFor="exampleColorInput">Chat button bg</Form.Label>
          <Form.Control
            type="color"
            id="exampleColorInput"
            defaultValue={buttonColor}
            title="Choose button color"
            onChange={handleColorChange}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>

          <Form.Check
            id="exampleColorInput33"
            type="checkbox"
            label="Use DDC Icon"
            checked={useDdcIcon}
            onChange={handleUseDdcIconChange}
          />

        </Col>
      </Row>
      <Form.Group controlId="document-question" className="w-100 mt-3">
        <Form.Label>Robot embed code:</Form.Label>
        <Form.Control as="textarea" rows={2} value={embedCode} readOnly />
      </Form.Group>
      <Button type="button" onClick={copyCode} className="mt-2">
        Copy embed code
      </Button>
    </Card>
  );
};

export default GenerateEmbedCode;