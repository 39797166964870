import React, { useState } from 'react';
import { Button, Form, Card,ButtonGroup,Row, Col } from 'react-bootstrap';

const apiUrl = process.env.REACT_APP_DOMAIN;

interface UserDocumentsProps {
  username: string;
  documents: string[];
  refreshDocuments: () => void;
}

const UserDocuments: React.FC<UserDocumentsProps> = ({ username, documents, refreshDocuments }) => {
  const [typingIndicator, setTypingIndicator] = useState<string>('');

  const deleteSelectedDocuments = async () => {
    const selectedDocuments = Array.from(document.querySelectorAll('#user-documents option:checked'))
      .map(option => (option as HTMLOptionElement).value);

    if (!username) {
      alert('Please enter a user name.');
      return;
    }
    if (selectedDocuments.length === 0) {
      alert('Please select documents to delete.');
      return;
    }
    
    const confirmDelete = window.confirm('Are you sure you want to delete the selected documents?');
    if (!confirmDelete) return;

    try {
      setTypingIndicator('Processing...');
      const response = await fetch(`${apiUrl}/vectorstore/documents?user=${encodeURIComponent(username)}&documents=${selectedDocuments.join(',')}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (response.ok) {

        refreshDocuments();
      } else {
        alert('Failed to delete selected documents.');
      }
      setTypingIndicator('');
    } catch (error) {
      console.error('Error deleting documents:', error);
    }
  };

  const deleteAllDocuments = async () => {
    if (!username) {
      alert('Please enter a user name.');
      return;
    }

    const confirmDelete = window.confirm('Are you sure you want to delete all documents?');
    if (!confirmDelete) return;

    try {
      const response = await fetch(`${apiUrl}/vectorstore/documents/all?user=${encodeURIComponent(username)}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (response.ok) {

        refreshDocuments();
      } else {
        alert('Failed to delete all documents.');
      }
    } catch (error) {
      console.error('Error deleting all documents:', error);
    }
  };

  return (
    <Card className="p-3 mb-3 mt-3">
      <Form.Group controlId="user-documents" className='w-100'>
        <Form.Label>Knowledge base:</Form.Label>
        <Form.Control as="select" multiple className='mb-2'>
          {documents.map((doc, index) => (
            <option key={index} value={doc}>{doc}</option>
          ))}
        </Form.Control>
      </Form.Group>
      <ButtonGroup>
        <Button onClick={deleteSelectedDocuments} type="submit" variant="warning w-80">Delete Selected</Button>
        <Button onClick={deleteAllDocuments} type="submit" variant="danger" className="ml-2 w-20">Delete All</Button>
      </ButtonGroup>
    </Card>
  );
};

export default UserDocuments;
