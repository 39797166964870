import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Card,ButtonGroup,Row, Col } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import human from "../assets/human.png";
import robot from "../assets/robot.png";
import brokenRobot from "../assets/broken_robot.png";

interface AskDocumentQuestionProps {
  username: string;
}

const AskDocumentQuestion: React.FC<AskDocumentQuestionProps> = ({ username }) => {
  const [question, setQuestion] = useState<string>('');
  const [readonly, setReadOnly] = useState<boolean>(false);
  const [response, setResponse] = useState<string>('');
  const [typingIndicator, setTypingIndicator] = useState<string>('');
  const responseRef = useRef<HTMLDivElement>(null);

  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      askDocumentStreamQuestion();
    }
  };

  const askDocumentStreamQuestion = async () => {
    if (question.trim() === '') return;

    setTypingIndicator('Processing...');
    const userQuestion = `<span style="color: #ff6701;">🤷‍♂️ : ${question}</span><br>`;
    setResponse((prev) => prev + userQuestion);

    const controller = new AbortController();
    const signal = controller.signal;
    const timeoutId = setTimeout(() => controller.abort(), 50000); // 5 seconds timeout

    try {
      const res = await fetch(`https://themain.ro/ai/document-chat-stream?user=${encodeURIComponent(username)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ question }),
        signal
      });

      clearTimeout(timeoutId);

      if (!res.ok) {
        throw new Error(`Server responded with status ${res.status}`);
      }

      const reader = res.body?.getReader();
      const decoder = new TextDecoder("utf-8");

      if (reader) {
        setQuestion('');
        setReadOnly(true);
        let done = false;
        let first = true;
        let assistantResponse = `<span style="color: #8d03ff;">🤖 : `;
        setResponse((prev) => prev + assistantResponse);
        while (!done) {
          const { value, done: doneReading } = await reader.read();
          done = doneReading;
          setReadOnly(!doneReading);
          let chunk = decoder.decode(value, { stream: true });

          // Format the chunk text
          chunk = chunk.replace(/\n/g, '<br>').replace(/\t/g, '&nbsp;&nbsp;&nbsp;');

          for (let i = 0; i < chunk.length; i++) {
            const char = chunk[i];
            setResponse((prev) => prev + char);
            await new Promise((resolve) => setTimeout(resolve, 5)); // Adjust the delay as needed
          }

          first = false;
        }
        setResponse((prev) => prev + `</span><br>`);
      }

      setTypingIndicator('');
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === 'AbortError') {
          setResponse((prev) => prev + `<div class="robotError"><img src=${brokenRobot}></div>`);
        } else {
          setResponse((prev) => prev + `<div class="robotError"><img src=${brokenRobot}></div>`);
        }
      } else {
        setResponse((prev) => prev + '<div class="robotError"><img src=${brokenRobot}></div>');
      }
      console.error('Fetch error:', error);
    } finally {
      setTypingIndicator('');
    }
  };

  const clearDocumentChat = () => {
    setResponse('');
  };

  useEffect(() => {
    if (responseRef.current) {
      responseRef.current.scrollTop = responseRef.current.scrollHeight;
    }
  }, [response]);

  return (
    <Card className='p-3 mb-3'>
      <div
        className="response"
        ref={responseRef}
        style={{ height: '400px', overflowY: 'auto', border: '1px solid #ddd', padding: '10px' }}
        dangerouslySetInnerHTML={{ __html: response }}
      />
      <Row className='mt-2'>
        <Col md={8}>
        <Form.Group controlId="document-question" className='w-100'>
          <Spinner animation="border" variant="primary" className='typing' style={{ display: typingIndicator ? 'inline-block' : 'none' }} />
          <Form.Control
            as="input"
            value={question}
            placeholder='Query knowledge base'
            onChange={handleQuestionChange}
            onKeyPress={handleKeyPress}
            disabled={readonly}
          />
        </Form.Group>
        </Col>
        <Col md={4}>
        <ButtonGroup aria-label="Basic example" className='w-100'>
          <Button onClick={askDocumentStreamQuestion}>Ask</Button>
          <Button type="button" variant="secondary" onClick={clearDocumentChat} className="ml-2">Clear</Button>
      </ButtonGroup>
        </Col>
      </Row>

    </Card>
  );
};

export default AskDocumentQuestion;
